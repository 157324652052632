import React, { useState } from "react";
import { Popover, Avatar } from "antd";
import moment from "moment";
import { convertMinutesToHoursAndMinutes } from "../../helpers/utils";
import _ from "lodash";

const CarItem = ({
  setOpenChooseCar,
  data,
  setCarSelected,
  addPrice = 0,
  area,
}) => {
  const [openNotification, setOpenNotification] = useState(false);
  const [openImportantNotification, setOpenImportantNotification] =
    useState(false);

  return (
    <div
      className="mt-2 bg-white rounded-[8px] w-full my-[16px]"
      style={{
        border: "1px solid #B4B4B4",
      }}
      onClick={() => {
        setOpenChooseCar(true);
        setCarSelected(data);
      }}
    >
      <div
        className="flex flex-col px-4 py-3"
        style={{
          borderBottom: "1px dashed rgb(224, 224, 224)",
        }}
      >
        <div className="flex mb-2">
          <div className="text-secondary font-bold mr-2 text-[24px]">
            {moment(data?.route?.schedules[0]?.pickup_date).format("HH:mm")}
          </div>
          <div className="flex items-center flex-1">
            <img
              src="/images/pickup_blue.svg"
              alt=""
              srcset=""
              className="w-[12px]"
            />
            <div
              style={{
                borderBottom: "1px solid rgb(224, 224, 224)",
                flex: 1,
              }}
            ></div>
            <div className="text-secondary text-[12px] mx-1">
              {" "}
              {convertMinutesToHoursAndMinutes(data?.route?.duration)}
            </div>
            <div
              style={{
                borderBottom: "1px solid rgb(224, 224, 224)",
                flex: 1,
              }}
            ></div>
            <img
              src="/images/pickup_red.svg"
              alt=""
              srcset=""
              className="w-[12px]"
            />
          </div>
          <div className="text-secondary font-bold ml-2 text-[24px]">
            {" "}
            {moment(data?.route?.schedules[0]?.arrival_time).format("HH:mm")}
          </div>
        </div>
        <div className="flex gap-3 justify-between">
          <p className="text-secondary font-bold text-[10px] mb-0 text-left">
            {data?.route?.pickup_points[0]?.name} ({area.from.name})
          </p>
          <p className="text-secondary font-bold text-[10px] mb-0 text-right">
            {data?.route?.dropoff_points[0]?.name} ({area.to.name})
          </p>
        </div>
      </div>
      <div
        className="flex px-4 py-3 justify-between"
        style={{
          borderBottom: "1px dashed rgb(224, 224, 224)",
        }}
      >
        <div className="flex gap-2">
          <Avatar
            className="cursor-pointer w-[32px] h-[32px] rounded-[50%] object-cover"
            src={`${
              data?.company?.images[0]?.files["1000x600"]
                ? `https://${data?.company?.images[0]?.files["1000x600"]}`
                : `https://storage.googleapis.com/fe-production/icon_horizontal.svg`
            }`}
          />
          <div className="flex flex-col gap-2">
            <div className="flex gap-1">
              <span className="text-[14px] text-secondary font-semibold">
                {data?.company?.name}
              </span>
              <img src="/images/money-blue.svg" alt="" srcset="" />
            </div>
            <div
              className="text-[12px]"
              style={{
                color: "rgb(36, 116, 229)",
              }}
            >
              {data?.route?.schedules[0]?.vehicle_type || ""}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-1">
            <span className="text-[18px] text-secondary font-semibold">
              {_.get(data, "company.ratings.overall", "updating")}
            </span>
            <img src="/images/star.svg" alt="" srcset="" />
          </div>
          <div className="text-[10px] text-[#b8b8b8]">
            {_.get(data, "company.ratings.comments", "updating")} đánh giá
          </div>
        </div>
      </div>
      <div className="flex flex-col px-4 py-3 gap-1">
        {data?.route?.schedules[0]?.fare.discount > 0 && (
          <div className="items-end text-[12px] text-[#b8b8b8] line-through mr-auto text-right w-full">
            {(
              data?.route?.schedules[0]?.fare.original + addPrice
            ).toLocaleString("vi-VN")}{" "}
          </div>
        )}
        <div className="flex justify-between">
          <div
            className="text-[16px]"
            style={{
              color: "rgb(36, 116, 229)",
            }}
          >
            Còn {data?.route?.schedules[0]?.total_available_seats} chỗ trống
          </div>

          <div className="flex gap-2">
            {data?.route?.schedules[0]?.fare.original !==
              data?.route?.schedules[0]?.fare.max && (
              <span className="text-[12px]">Từ</span>
            )}
            <div className="text-secondary text-[20px] font-semibold">
              {" "}
              {(
                (data?.route?.schedules[0]?.fare.discount > 0
                  ? data?.route?.schedules[0]?.fare.discount
                  : data?.route?.schedules[0]?.fare.original) + addPrice
              ).toLocaleString("vi-VN")}{" "}
              đ
            </div>
          </div>
        </div>
        {/* <div className="w-full text-right text-[#484848] uppercase text-[10px] font-semibold">
          Không cần thanh toán trước
        </div> */}
        {/* <div className="flex gap-2 my-2">
          <Tag color="error" className="h-5 leading-5">
            <span className="text-[10px]">Giảm 50% tối đa 250K</span>
          </Tag>
          <Tag color="error" className="h-5 leading-5">
            <span className="text-[10px]">Giảm 25%</span>
          </Tag>
        </div> */}
      </div>

      {data?.important_notification?.label && (
        <Popover
          content={
            <pre
              onClick={() => setOpenImportantNotification(false)}
              style={{
                lineHeight: 2,
                whiteSpace: "pre-line",
                fontSize: 10,
              }}
            >
              {data?.important_notification?.content}
            </pre>
          }
          title=""
          trigger="click"
          open={openImportantNotification}
          placement="bottom"
          onOpenChange={(newOpen) => setOpenImportantNotification(newOpen)}
        >
          <button
            className="border-0 flex w-full"
            style={{
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            }}
          >
            <div
              className="bg-[#2474e5] flex p-2 gap-2"
              style={{
                borderBottomLeftRadius: 8,
              }}
            >
              <img
                src="/images/tag-white.svg"
                alt=""
                srcset=""
                className="w-[16px]"
              />
              <div className="text-white font-semibold  text-[12px]">
                Quy định
              </div>
            </div>
            <div
              className="bg-[#ECF4FD] p-2 flex gap-2 flex-1 items-center justify-center"
              style={{
                borderBottomRightRadius: 8,
              }}
            >
              <span className="text-[#2474e5] text-[14px]">
                {data?.important_notification?.label}
              </span>
              <img src="/images/icon-noti-blue.svg" alt="" srcset="" />
            </div>
          </button>
        </Popover>
      )}

      {data?.notification?.label && (
        <Popover
          content={
            <pre
              onClick={() => setOpenNotification(false)}
              style={{
                lineHeight: 2,
                whiteSpace: "pre-line",
                fontSize: 10,
              }}
            >
              {data?.notification?.content}
            </pre>
          }
          title=""
          trigger="click"
          open={openNotification}
          placement="bottom"
          onOpenChange={(newOpen) => setOpenNotification(newOpen)}
        >
          <button
            className="border-0 flex w-full"
            style={{
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            }}
          >
            <div
              className="bg-[#2474e5] flex p-2 gap-2 items-center"
              style={{
                borderBottomLeftRadius: 8,
              }}
            >
              <img
                src="/images/tag-white.svg"
                alt=""
                srcset=""
                className="w-[16px]"
              />
              <div className="text-white font-semibold  text-[12px]">
                {data?.notification?.id === 3 ? "Ưu đãi" : "Thông báo"}
              </div>
            </div>
            <div
              className="bg-[#ECF4FD] p-2 flex gap-2 flex-1 items-center justify-center"
              style={{
                borderBottomRightRadius: 8,
              }}
            >
              <span className="text-[#2474e5] text-[12px]">
                {data?.notification?.label}
              </span>
              <img src="/images/icon-noti-blue.svg" alt="" srcset="" />
            </div>
          </button>
        </Popover>
      )}
    </div>
  );
};

export default CarItem;
